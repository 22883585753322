import * as React from 'react'
import {graphql} from 'gatsby'
import Layout from '../components/Layout'
import {PostsList} from '../components/PostsList'
import SEO from '../components/seo'

export default function TagPage({
  location,
  pageContext: {tag},
  data: {allMdx,site},
}) {
  return (
    <Layout location={location} title={site.siteMetadata.title}>
        <SEO title={`Posts tagged "${tag}"`} keywords={[tag]} />
        <h2>Posts Tagged "{tag}"</h2>
        <PostsList posts={allMdx.edges} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query TagPage($tag: String) {
    site {
     siteMetadata {
       title
     }
   }
    allMdx(
      limit: 1000
      sort: {fields: [frontmatter___date], order: DESC}
      filter: {frontmatter: {tags: {in: [$tag]}, published: {ne: false}}}
    ) {
      totalCount
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            tags
            date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
  }
`
